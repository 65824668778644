.about {
  height: 100vh;
}
.about .about_container {
  margin: 0 5%;
}
.about .about_container .about_wrapper {
  padding: 2rem 0;
}
.about .about_container .about_wrapper .about_header_title {
  color: var(--text-color);
  font-size: 4rem;
  font-weight: 600;
  font-family: "IBM Plex Mono", monospace;
  text-align: center;
  margin-bottom: 2rem;
}
.about .about_container .about_wrapper .abt_sub_title {
  font-family: Apercu, Roboto, "Courier New", Courier, monospace;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
.about .about_container .about_wrapper .about_paragraphs {
  font-family: Apercu, Roboto, "Courier New", Courier, monospace;
  font-size: 1.25rem;
  font-weight: 200;
  margin-bottom: 2rem;
}
.about .about_container .about_wrapper .about_paragraphs .sendicon {
  height: 20px;
  width: 20px;
  fill: #248aff;
  margin-bottom: -2px;
}
.about .about_container .about_wrapper .abt_table {
  border: 1px solid #2b2b2b;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  font-family: Apercu, Roboto, "Courier New", Courier, monospace;
}
.about .about_container .about_wrapper .abt_table th,
.about .about_container .about_wrapper .abt_table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #2b2b2b;
}
.about .about_container .about_wrapper .abt_table thead {
  background-color: #9ee0ff;
}
.about .about_container .about_wrapper .abt_table thead tr > th {
  font-weight: 700;
  font-size: 1.3rem;
}/*# sourceMappingURL=about.css.map */