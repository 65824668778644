.showcasecontloading {
  width: 100%;
  display: flex;
  gap: 1rem;
  .c-skeleton-square {
    height: 400px;
    width: 50%;
    border-radius: 10px;
  }
  .c-item__right {
    width: 50%;
    .c-skeleton-line {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      margin-bottom: 15px;
    }
  }
}

@keyframes loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.c-skeleton-line,
.c-skeleton-circle,
.c-skeleton-square {
  background-color: #c2c2ff;
  display: inline-flex;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
  opacity: 0.9;
}

.c-skeleton-line:after,
.c-skeleton-circle:after,
.c-skeleton-square:after {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  //background-image: linear-gradient(90deg, #494949, #6b6b6b, #494949);
  background-image: linear-gradient(90deg, #c2c2ff, #d2d2ff, #c2c2ff);
  transform: translateX(-100%);
  animation-name: loading-skeleton;
  animation-direction: normal;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
