@use 'main/fonts.scss';
@use 'main/variables.scss';

*,
::after,
::before {
  position: relative;
  padding: 0;
  margin: 0;
  border-style: border-box;
  -webkit-font-smoothing: inherit;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

input {
  outline: none;
}

html,
body {
  text-size-adjust: 100%;
  scroll-behavior: smooth;
  font-family: Volte Rounded, Apercu, Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: none;
  direction: ltr;
  transition: background 2s;
  position: relative;
}

.app {
  background: linear-gradient(
    180deg,
    var(--background-color-one) 0%,
    var(--background-color-two) 20%,
    var(--background-color-three) 100%
  );
  transition: all 0.3s ease-in-out 0s;
}

/* Remove increment and decrement buttons from number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background: linear-gradient(
    180deg,
    var(--background-color-one) 0%,
    var(--background-color-two) 20%,
    var(--background-color-three) 100%
  ) !important;
  transition: all 0.3s ease-in-out 0s;
  color: fieldtext !important;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(to bottom right, #14a9ff, #0088f0) !important;
  border: none;
  border-radius: 23px;
  color: #fff !important;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  line-height: 1.5rem;
  margin: 10px auto;
  outline: none;
  padding: 5px 20px;
  transition: 0.4s;
  width: max-content;
}

.r_blg_body__content,
.out_th_content,
.inc_th_content {
  font-family: Roboto, Apercu, 'Space Mono', monospace, sans-serif;
  font-weight: 300;
  font-style: normal;
}

//loading animation
/**
 * ==============================================
 * dot-flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin: 0 auto;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #ffffff;
  }
  50%,
  100% {
    background-color: #fff3f333;
  }
}

.hide {
  display: none !important;
}
