.report_pdf input,
.report_pdf button {
  font: inherit;
}
.report_pdf {
  position: relative;
}

.report_pdf header {
  background-color: #323639;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  color: white;
}

.report_pdf header h1 {
  font-size: inherit;
  margin: 0;
}

.report_pdf__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
}

.report_pdf__container__load {
  margin-top: 1em;
  color: white;
}

.report_pdf__container__document {
  width: 100%;
  max-width: calc(100% - 2em);
  margin: 1em 0;
}

.report_pdf__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report_pdf__container__document .react-pdf__Page {
  margin: 1em 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.report_pdf__container__document .react-pdf__message {
  padding: 20px;
  color: white;
}
.report_pdf_scroll_to_top {
  position: sticky;
  bottom: 20px;
  right: 20px;
  background-color: #0088f0;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  cursor: pointer;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: 2s ease-in-out;
  .report_pdf_scroll_to_top_ic {
    height: 25px;
    width: 25px;
    transform: rotate(-90deg);
    fill: #fff;
  }
}
