.chat {
  height: 100vh;
  position: relative;
  .chatmodalpop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #00000079;
    .chatmodalinnercont_wrapper {
      position: relative;
      height: 100%;
      width: 100%;
      .chatmodalinnercont {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        width: 400px;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 5px;
        .newbot_form {
          width: 70%;
          margin: 0 auto;
          .newbot_form_group {
            width: 100%;
            .new_form_label {
              margin: 0 0 10px 5px;
            }
            .newbot_form_input {
              outline: none;
              border: 1px solid #000;
              border-radius: 23px;
              padding: 10px 20px;
              font-size: 1.2rem;
              font-weight: 700;
              width: calc(100% - 40px);
              margin-top: 10px;
            }
          }
          .newbot_name_submit_form {
            display: flex;
            align-items: center;
            justify-content: center;

            background: linear-gradient(
              to bottom right,
              #14a9ff,
              #0088f0
            ) !important;
            border: none;
            border-radius: 23px;
            color: #fff !important;
            cursor: pointer;
            font-family: Roboto, sans-serif;
            font-weight: 700;
            line-height: 1.5rem;
            margin: 20px auto;
            outline: none;
            padding: 10px 20px;
            transition: 0.4s;
            width: 100%;
            .pa_plus_Ic {
              height: 15px;
              width: 15px;
              fill: #fff !important;
            }
            span {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .chat_container {
    .chatAppbar {
      background-color: var(--background-modals);
      height: 54px;
      padding: 0 5%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .chatbar_left {
        display: flex;
        align-items: center;
        .chat_profile_pic {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          overflow: hidden;
          border: 1px solid #9935e8;
          margin: 0 20px;
          cursor: pointer;
        }
        h1 {
          font-family: 'IBM Plex Mono', monospace;
          font-size: 2rem;
          font-weight: 600;
        }
      }
    }
    .chatWrapper {
      width: 90%;
      margin: 0 auto;
      margin-top: 20px;
      display: flex;
      justify-content: space-evenly;
      column-gap: 20px;
      .lsdractive {
        width: 23% !important;
      }
      .newChatDrawer {
        width: 0px;
        height: calc(100vh - 94px);
        overflow: hidden;
        .newChatDrawerCloseIc {
          cursor: pointer;
          transform: rotate(180deg);
          height: 20px;
          width: 20px;
          margin-left: 5px;
          margin-bottom: 10px;
        }
      }
      .leftSidebar {
        width: 0px;
        height: calc(100vh - 94px);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .lsbar_search {
          margin-bottom: 20px;
          .lsdbar_searchform {
            display: flex;
            align-items: center;
            background-color: var(--background-modals);
            border-radius: 5px;
            width: 100%;
            .lsdbar_form_group {
              flex: 1;
              .lsdbar_search_input {
                padding: 10px;
                border: none;
                width: 95%;
                background-color: transparent;
              }
            }
            .lsdbar_search_button {
              all: unset;
              cursor: pointer;
              padding: 7px 10px 5px 10px;
              .lsdbar_search_Ic {
                height: 15px;
                width: 15px;
              }
            }
          }
        }
        .lsdbar_categories {
          display: flex;
          flex-direction: column;
          flex: 1;
          .lsdbar_item_cat_active {
            flex: 1;
          }
          .lsdbar_cat_item {
            margin-bottom: 2px;
            border-bottom: 1px solid #bfbfbf;
            .lsbar_it_title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              padding: 5px;
              border-radius: 5px;
              &:hover {
                background-color: var(--background-modals);
              }
              span {
                text-transform: uppercase;
              }
              .lsdbar_it_Ic {
                height: 15px;
                width: 15px;
                margin-top: 2px;
              }
            }
            .lsdbar_cat_cont {
              height: min-content;
              overflow-y: scroll;
              &::-webkit-scrollbar {
                display: none;
              }
              .wecare_it_user {
                padding: 10px 0 10px 10px;
                display: flex;
                align-items: flex-start;
                cursor: pointer;
                border-radius: 5px;
                &:hover {
                  background-color: #fff;
                }
                .wecare_lsdbar_profile {
                  min-width: 30px;
                  height: 30px;
                  width: 30px;
                  border-radius: 50%;
                  overflow: hidden !important;
                  cursor: pointer;
                  border: 2px solid #9935e8;
                  .wecare_user_profile_sidebar {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    margin-top: 5px;
                  }
                }
                .lsdbar_user_profile_texts {
                  margin-left: 10px;
                  flex: 1;
                  .lsdbar_user_name {
                    font-family: 'Apercu';
                    font-size: 1.1rem;
                    font-weight: 400;
                    line-height: 18px;
                    color: var(--text-color);
                    user-select: none;
                  }
                  .lsdbar_last_mess {
                    font-size: 0.85rem;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    word-wrap: break-word;
                    color: var(--text-color);
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    user-select: none;
                  }
                }
                .lsdbar_user_profile_meta {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  justify-content: flex-start;
                  padding-right: 10px;
                  padding-bottom: 20px;
                  .lsdbar_lst_time {
                    font-size: 0.7rem;
                    user-select: none;
                  }
                }
              }
              .lsdbar_new_chat {
                display: flex;
                align-items: center;
                justify-content: center;

                background: linear-gradient(
                  to bottom right,
                  #14a9ff,
                  #0088f0
                ) !important;
                border: none;
                border-radius: 23px;
                color: #fff !important;
                cursor: pointer;
                font-family: Roboto, sans-serif;
                font-weight: 700;
                line-height: 1.5rem;
                margin: 20px 0;
                outline: none;
                padding: 10px 20px;
                transition: 0.4s;
                .pa_plus_Ic {
                  height: 15px;
                  width: 15px;
                  fill: #fff !important;
                }
                span {
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }
      .playarea {
        flex: 1;
        background-color: var(--background-modals);
        border-radius: 10px;
        height: calc(100vh - 94px);
        .playarea_placeholder {
          position: relative;
          height: 100%;
          .playarea_place_center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .playarea_place_bottom {
            position: absolute;
            left: 50%;
            bottom: 20px;
            transform: translateX(-50%);
          }
        }
        .playarea_active {
          height: 100%;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          .pa_top {
            height: min-content;
            border-bottom: 1px solid #bfbfbf;
            box-shadow: 0px 2px 8px rgba(99, 99, 99, 0.2);
            padding: 5px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            .active_chatee_left {
              display: flex;
              align-items: center;
              .active_chatee_profile {
                min-width: 30px;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                overflow: hidden !important;
                cursor: pointer;
                border: 2px solid #9935e8;
                margin-right: 10px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                }
              }
              .active_chatee_meta {
                h2 {
                  font-family: 'Apercu';
                  font-size: 1.1rem;
                  font-weight: 400;
                  line-height: 18px;
                  color: var(--text-color);
                  cursor: pointer;
                }
                .active_chatee_ls {
                  font-size: 0.85rem;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  word-wrap: break-word;
                  color: var(--text-color);
                  display: -webkit-box;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
            .active_chatee_right {
              .three_vertical_dots {
                height: 20px;
                width: 20px;
                cursor: pointer;
                padding: 7px;
                border-radius: 50%;
                overflow: hidden;
                margin: 3px;
                &:hover {
                  background-color: #41414197;
                }
              }
            }
            .active_chatee_pop_up {
              position: absolute;
              right: 10px;
              top: 50px;
              z-index: 999;
              background-color: #fff;
              height: min-content;
              width: 150px;
              box-shadow: 0px 2px 8px #4d4d4d91;
              border-radius: 5px;
              overflow: hidden;
              ul {
                li {
                  padding: 5px 5px 5px 10px;
                  cursor: pointer;
                  &:hover {
                    background-color: #89898997;
                  }
                }
              }
            }
          }
          .pa_middle {
            height: calc(100vh - 94px - 35px - 50px);
            padding: 15px;
            padding-bottom: 5px;
            overflow-y: scroll;
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-start;
            &::-webkit-scrollbar {
              display: none;
            }
            .messplay_sec_info {
              background-color: var(--background-modals);
              color: var(--text-color);
              padding: 5px;
              border-radius: 5px;
              width: 50%;
              margin: 0 auto;
              align-self: center;
              text-align: center;
              font-size: 0.8rem;
              cursor: pointer;
              align-self: flex-start;
            }
            .messplay {
              display: flex;
              flex-direction: column;
              width: 100%;
              flex: 1;

              .incoming_message {
                display: flex;
                align-items: flex-start;
                width: 80%;
                margin: 20px 0;
                align-self: flex-start;
                .in_mess_sender_profile {
                  min-width: 30px;
                  height: 30px;
                  width: 30px;
                  border-radius: 50%;
                  overflow: hidden !important;
                  cursor: pointer;
                }
                .helpline_message {
                  background-color: #92d0ff !important;
                }
                .in_mess_content {
                  margin-left: 10px;
                  background-color: var(--background-modals);
                  padding: 5px;
                  border: 1px solid #d6d6d6;
                  border-radius: 5px;
                  min-width: 70px;
                  .inc_mess_meta {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    .inc_sender_name {
                      font-family: 'Apercu';
                      font-size: 0.8rem;
                    }
                    .inc_mess_time {
                      font-size: 0.8rem;
                    }
                    .inc_mess_misc {
                      font-family: 'Apercu';
                      background-color: #ff9428;
                      padding: 2px 3px;
                      border-radius: 3px;
                      font-size: 0.6rem;
                      font-weight: 800;
                    }
                  }
                  .incoming_cont_message {
                    padding-top: 5px;
                    .inc_th_content {
                      font-family: 'Apercu';
                      font-size: 1.2rem;
                      font-weight: 500;
                    }
                  }
                }
              }
              .outgoing_message {
                display: flex;
                align-items: flex-start;
                max-width: 80%;
                margin: 20px 0;
                align-self: flex-end;
                cursor: pointer;
                .out_mess_content {
                  margin-right: 10px;
                  background-color: var(--background-modals);
                  padding: 5px;
                  border: 1px solid #d6d6d6;
                  border-radius: 5px;
                  min-width: 70px;
                  .out_mess_meta {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    .out_mess_time {
                      font-size: 0.8rem;
                    }
                  }
                  .outgoing_cont_message {
                    padding-top: 5px;
                    .out_th_content {
                      font-family: 'Apercu';
                      font-size: 1.2rem;
                      font-weight: 500;
                    }
                  }
                }
                .out_mess_sender_profile {
                  min-width: 30px;
                  height: 30px;
                  width: 30px;
                  border-radius: 50%;
                  overflow: hidden !important;
                  cursor: pointer;
                }
              }
            }
          }
          .pa_bottom {
            height: min-content;
            .pa_chat_wrapper {
              display: flex;
              align-items: center;
              .pa_widgets {
                padding: 0 10px;
                .pa_plus_Ic {
                  height: 20px;
                  width: 20px;
                  cursor: pointer;
                }
              }
              .pa_chat_form {
                flex: 1;
                .pa_form_chat {
                  display: flex;
                  align-items: center;
                  .pa_chat_input {
                    flex: 1;
                    background-color: var(--background-color-one);
                    outline: none;
                    border-radius: 5px;
                    display: block;
                    min-height: 30px;
                    height: 30px;
                    max-height: 98px;
                    overflow-x: auto;
                    line-height: 21px;
                    padding: 10px;
                    font-size: 15px;
                    line-height: 25px;
                    float: left;
                    border: 1px solid gray;
                    resize: none;
                    &::-webkit-scrollbar {
                      display: none;
                    }
                  }
                  .pa_chat_submit_btn {
                    all: unset;
                    padding: 10px 20px;
                    cursor: pointer;
                    .pa_send_Ic {
                      height: 20px;
                      width: 20px;
                    }
                  }
                }
              }
            }
            .pa_btm_info {
              text-align: center;
              margin: 5px 0;
              font-size: 0.8rem;
            }
          }
        }
      }
      .rightSidebar {
        width: 0%;
        background-color: var(--background-modals);
        border-radius: 10px;
        height: calc(100vh - 94px);
      }
    }
  }
}

[contentEditable='true'] {
  &:empty {
    &:not(:focus) {
      &:before {
        content: attr(data-text);
      }
    }
  }
}

.typing {
  display: block;
  width: 60px;
  height: 40px;
  border-radius: 20px;
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #8d8d8d;
  margin: 3px;

  &.scaling {
    animation: typing 1000ms ease-in-out infinite;
    animation-delay: 3600ms;
  }

  &.bouncing {
    animation: bounce 1000ms ease-in-out infinite;
    animation-delay: 3600ms;
  }

  &:nth-child(1) {
    animation-delay: 0ms;
  }

  &:nth-child(2) {
    animation-delay: 333ms;
  }

  &:nth-child(3) {
    animation-delay: 666ms;
  }
}

@keyframes typing {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.wecare_it_user {
  padding: 10px 0 10px 10px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #fff;
  }
  .wecare_lsdbar_profile {
    min-width: 30px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    overflow: hidden !important;
    cursor: pointer;
    border: 2px solid #9935e8;
    .wecare_user_profile_sidebar {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      margin-top: 5px;
    }
  }
  .lsdbar_user_profile_texts {
    margin-left: 10px;
    flex: 1;
    .lsdbar_user_name {
      font-family: 'Apercu';
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 18px;
      color: var(--text-color);
      user-select: none;
    }
    .lsdbar_last_mess {
      font-size: 0.85rem;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      word-wrap: break-word;
      color: var(--text-color);
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      user-select: none;
    }
  }
  .lsdbar_user_profile_meta {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end !important;
    padding-right: 10px;
    padding-bottom: 0px !important;
    .lsdbar_lst_time {
      font-size: 0.7rem;
      user-select: none;
    }
    .lsdbar_lst_delete_bot {
      height: 15px;
      width: 15px;
      cursor: pointer;
      fill: #ff7676;
      transition: 2s;

      &:hover {
        fill: red;
        transform: scale(1.05);
      }
    }
  }
}

.r_blg_body__content {
  p {
    margin-bottom: 10px;
    strong {
      font-size: 1.5rem;
    }
  }
  ul {
    margin: 10px 20px 10px 25px;
    li {
      margin-bottom: 10px;
      list-style: circle;
    }
  }
}
