.profie_picture_wrapper {
  position: relative;
  height: 160px;
  width: 160px;
  border-radius: 50%;
  margin: 20px auto 40px auto;
  overflow: hidden !important;
  .noborder {
    border: none !important;
  }
  .profie_picture {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    overflow: hidden !important;
    border: 2px solid #9935e8;
    cursor: pointer;
    margin: 3px auto;
    position: relative;
    .the_profile_picture {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      margin-top: 20px;
      position: relative;
      z-index: 0;
    }
    .m_t_unset {
      margin-top: unset !important;
    }
    .profile_picture_file_field {
      height: 100%;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      width: 100%;
      top: 0px;
      left: 0px;
      cursor: pointer;
    }
    .profile_picture_camera_ic {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 25px;
      width: 25px;
      fill: #b0b0b0;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      padding: 5px;
      pointer-events: none;
      cursor: pointer;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(5turn);
  }
}
.moving-border {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
    width: 100%;
    height: 100%;
    background-color: #399953;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#399953, #399953),
      linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33),
      linear-gradient(#377af5, #377af5);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 5px;
    top: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: white;
    border-radius: 50%;
  }
}
