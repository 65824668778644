:root {
  --angle: 0deg;
}

.auth {
  height: 100vh;
  position: relative;
}
.auth .auth-form {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(180deg, var(--background-color-one) 0%, var(--background-color-two) 20%, var(--background-color-three) 100%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.auth .auth-form h2 {
  margin: 0 0 30px 0;
  font-weight: 600;
  font-size: 2rem;
  font-family: "Courier New", Courier, monospace;
}
.auth .auth-form .error_info {
  color: red;
  padding-bottom: 10px;
}
.auth .auth-form form {
  display: flex;
  flex-direction: column;
}
.auth .auth-form form .form_group {
  width: 100%;
  margin-bottom: 30px;
  border-bottom: 1px solid #848484;
  border-radius: 5px;
  overflow: hidden;
}
.auth .auth-form form .form_group input {
  width: calc(100% - 40px);
  padding: 10px 20px;
  border: none;
  font-size: 1.2rem;
}
.auth .auth-form form button {
  background-color: #007bff;
  color: #fff;
  padding: 15px;
  border: none;
  border-radius: 23px;
  cursor: pointer;
  margin-bottom: 2rem;
  font-weight: 800;
  letter-spacing: 1.7px;
  text-transform: uppercase;
}
.auth .auth-form p {
  margin-top: 10px;
  font-family: "Courier New", Courier, monospace;
  font-size: 1.1rem;
}
.auth .auth-form p span {
  cursor: pointer;
  color: #007bff;
}
.auth .auth-form .image_upload {
  padding-bottom: 20px;
}
.auth .auth-form .image_upload .image_upload_title {
  font-size: 23px;
}
.auth .auth-form .image_upload .profie_picture_wrapper {
  position: relative;
  height: 160px;
  width: 160px;
  border-radius: 50%;
  margin: 20px auto 40px auto;
  overflow: hidden !important;
}
.auth .auth-form .image_upload .profie_picture_wrapper .noborder {
  border: none !important;
}
.auth .auth-form .image_upload .profie_picture_wrapper .profie_picture {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden !important;
  border: 2px solid #9935e8;
  cursor: pointer;
  margin: 3px auto;
  position: relative;
}
.auth .auth-form .image_upload .profie_picture_wrapper .profie_picture .the_profile_picture {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  margin-top: 20px;
  position: relative;
  z-index: 0;
}
.auth .auth-form .image_upload .profie_picture_wrapper .profie_picture .m_t_unset {
  margin-top: unset !important;
}
.auth .auth-form .image_upload .profie_picture_wrapper .profie_picture .profile_picture_file_field {
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
}
.auth .auth-form .image_upload .profile_skip {
  background: #2ac6ff;
  border-radius: 23px;
  padding: 9px 23px 11px;
  font-size: 16px;
  letter-spacing: 1.6px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-weight: 500;
  z-index: unset !important;
  cursor: pointer;
}

@keyframes rotate {
  100% {
    transform: rotate(5turn);
  }
}
.moving-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -3;
  width: 100%;
  height: 100%;
  background-color: #399953;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5);
  animation: rotate 4s linear infinite;
}
.moving-border::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 5px;
  top: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background: white;
  border-radius: 50%;
}/*# sourceMappingURL=auth.css.map */