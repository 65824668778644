.footer {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  .footer_wrapper {
    p {
      text-align: center;
    }
  }
}
