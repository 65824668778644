@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&family=Space+Mono:ital@0;1&display=swap');
/* Apercu font */
@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-Light.eot);
  src: url(../../assets/fonts/apercu/Apercu-Light.eot?#iefix)
      format('embedded-opentype'),
    url(../../assets/fonts/apercu/Apercu-Light.woff2) format('woff2'),
    url(../../assets/fonts/apercu/Apercu-Light.woff) format('woff'),
    url(../../assets/fonts/apercu/Apercu-Light.ttf) format('truetype'),
    url(../../assets/fonts/apercu/Apercu-Light.svg#Apercu-Light) format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-LightItalic.eot);
  src: url(../../assets/fonts/apercu/Apercu-LightItalic.eot?#iefix)
      format('embedded-opentype'),
    url(../../assets/fonts/apercu/Apercu-LightItalic.woff2) format('woff2'),
    url(../../assets/fonts/apercu/Apercu-LightItalic.woff) format('woff'),
    url(../../assets/fonts/apercu/Apercu-LightItalic.ttf) format('truetype'),
    url(../../assets/fonts/apercu/Apercu-LightItalic.svg#Apercu-LightItalic)
      format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-Regular.eot);
  src: url(../../assets/fonts/apercu/Apercu-Regular.eot?#iefix)
      format('embedded-opentype'),
    url(../../assets/fonts/apercu/Apercu-Regular.woff2) format('woff2'),
    url(../../assets/fonts/apercu/Apercu-Regular.woff) format('woff'),
    url(../../assets/fonts/apercu/Apercu-Regular.ttf) format('truetype'),
    url(../../assets/fonts/apercu/Apercu-Regular.svg#Apercu-Regular)
      format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-Italic.eot);
  src: url(../../assets/fonts/apercu/Apercu-Italic.eot?#iefix)
      format('embedded-opentype'),
    url(../../assets/fonts/apercu/Apercu-Italic.woff2) format('woff2'),
    url(../../assets/fonts/apercu/Apercu-Italic.woff) format('woff'),
    url(../../assets/fonts/apercu/Apercu-Italic.ttf) format('truetype'),
    url(../../assets/fonts/apercu/Apercu-Italic.svg#Apercu-Italic) format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-Medium.eot);
  src: url(../../assets/fonts/apercu/Apercu-Medium.eot?#iefix)
      format('embedded-opentype'),
    url(../../assets/fonts/apercu/Apercu-Medium.woff2) format('woff2'),
    url(../../assets/fonts/apercu/Apercu-Medium.woff) format('woff'),
    url(../../assets/fonts/apercu/Apercu-Medium.ttf) format('truetype'),
    url(../../assets/fonts/apercu/Apercu-Medium.svg#Apercu-Medium) format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-MediumItalic.eot);
  src: url(../../assets/fonts/apercu/Apercu-MediumItalic.eot?#iefix)
      format('embedded-opentype'),
    url(../../assets/fonts/apercu/Apercu-MediumItalic.woff2) format('woff2'),
    url(../../assets/fonts/apercu/Apercu-MediumItalic.woff) format('woff'),
    url(../../assets/fonts/apercu/Apercu-MediumItalic.ttf) format('truetype'),
    url(../../assets/fonts/apercu/Apercu-MediumItalic.svg#Apercu-MediumItalic)
      format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-Bold.eot);
  src: url(../../assets/fonts/apercu/Apercu-Bold.eot?#iefix)
      format('embedded-opentype'),
    url(../../assets/fonts/apercu/Apercu-Bold.woff2) format('woff2'),
    url(../../assets/fonts/apercu/Apercu-Bold.woff) format('woff'),
    url(../../assets/fonts/apercu/Apercu-Bold.ttf) format('truetype'),
    url(../../assets/fonts/apercu/Apercu-Bold.svg#Apercu-Bold) format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-BoldItalic.eot);
  src: url(../../assets/fonts/apercu/Apercu-BoldItalic.eot?#iefix)
      format('embedded-opentype'),
    url(../../assets/fonts/apercu/Apercu-BoldItalic.woff2) format('woff2'),
    url(../../assets/fonts/apercu/Apercu-BoldItalic.woff) format('woff'),
    url(../../assets/fonts/apercu/Apercu-BoldItalic.ttf) format('truetype'),
    url(../../assets/fonts/apercu/Apercu-BoldItalic.svg#Apercu-BoldItalic)
      format('svg');
  font-weight: 700;
  font-style: italic;
}

/* Volte Rounded */
@font-face {
  font-family: Volte Rounded;
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-Bold.woff2);
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-Bold.woff2)
    format('woff2');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: Volte Rounded;
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-BoldItalic.woff2);
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-BoldItalic.woff2)
    format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Volte Rounded;
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-Italic.woff2);
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-Italic.woff2)
    format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Volte Rounded;
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-Light.woff2);
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-Light.woff2)
    format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Volte Rounded;
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-LightItalic.woff2);
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-LightItalic.woff2)
    format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Volte Rounded;
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-Medium.woff2);
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-Medium.woff2)
    format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Volte Rounded;
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-MediumItalic.woff2);
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-MediumItalic.woff2)
    format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Volte Rounded;
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-Regular.woff2);
  src: url(../../assets/fonts/Volte-Rounded/VolteRounded-Regular.woff2)
    format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'VolteRounded-Bold';
  src: url('../../assets/fonts/VolteRounded-Bold/@font-face/428912faef6cbf7a2c43c3952c8de813.eot'); /* IE9*/
  src: url('../../assets/fonts/VolteRounded-Bold/@font-face/428912faef6cbf7a2c43c3952c8de813.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('../../assets/fonts/VolteRounded-Bold/@font-face/428912faef6cbf7a2c43c3952c8de813.woff2')
      format('woff2'),
    /* chrome、firefox */
      url('../../assets/fonts/VolteRounded-Bold/@font-face/428912faef6cbf7a2c43c3952c8de813.woff')
      format('woff'),
    /* chrome、firefox */
      url('../../assets/fonts/VolteRounded-Bold/@font-face/428912faef6cbf7a2c43c3952c8de813.ttf')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('../../assets/fonts/VolteRounded-Bold/@font-face/428912faef6cbf7a2c43c3952c8de813.svg#VolteRounded-Bold')
      format('svg'); /* iOS 4.1- */
  font-style: normal;
}
