.chat {
  height: 100vh;
  position: relative;
}
.chat .chatmodalpop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4745098039);
}
.chat .chatmodalpop .chatmodalinnercont_wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.chat .chatmodalpop .chatmodalinnercont_wrapper .chatmodalinnercont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 400px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 5px;
}
.chat .chatmodalpop .chatmodalinnercont_wrapper .chatmodalinnercont .newbot_form {
  width: 70%;
  margin: 0 auto;
}
.chat .chatmodalpop .chatmodalinnercont_wrapper .chatmodalinnercont .newbot_form .newbot_form_group {
  width: 100%;
}
.chat .chatmodalpop .chatmodalinnercont_wrapper .chatmodalinnercont .newbot_form .newbot_form_group .new_form_label {
  margin: 0 0 10px 5px;
}
.chat .chatmodalpop .chatmodalinnercont_wrapper .chatmodalinnercont .newbot_form .newbot_form_group .newbot_form_input {
  outline: none;
  border: 1px solid #000;
  border-radius: 23px;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: 700;
  width: calc(100% - 40px);
  margin-top: 10px;
}
.chat .chatmodalpop .chatmodalinnercont_wrapper .chatmodalinnercont .newbot_form .newbot_name_submit_form {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom right, #14a9ff, #0088f0) !important;
  border: none;
  border-radius: 23px;
  color: #fff !important;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  line-height: 1.5rem;
  margin: 20px auto;
  outline: none;
  padding: 10px 20px;
  transition: 0.4s;
  width: 100%;
}
.chat .chatmodalpop .chatmodalinnercont_wrapper .chatmodalinnercont .newbot_form .newbot_name_submit_form .pa_plus_Ic {
  height: 15px;
  width: 15px;
  fill: #fff !important;
}
.chat .chatmodalpop .chatmodalinnercont_wrapper .chatmodalinnercont .newbot_form .newbot_name_submit_form span {
  margin-left: 5px;
}
.chat .chat_container .chatAppbar {
  background-color: var(--background-modals);
  height: 54px;
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat .chat_container .chatAppbar .chatbar_left {
  display: flex;
  align-items: center;
}
.chat .chat_container .chatAppbar .chatbar_left .chat_profile_pic {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #9935e8;
  margin: 0 20px;
  cursor: pointer;
}
.chat .chat_container .chatAppbar .chatbar_left h1 {
  font-family: "IBM Plex Mono", monospace;
  font-size: 2rem;
  font-weight: 600;
}
.chat .chat_container .chatWrapper {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.chat .chat_container .chatWrapper .lsdractive {
  width: 23% !important;
}
.chat .chat_container .chatWrapper .newChatDrawer {
  width: 0px;
  height: calc(100vh - 94px);
  overflow: hidden;
}
.chat .chat_container .chatWrapper .newChatDrawer .newChatDrawerCloseIc {
  cursor: pointer;
  transform: rotate(180deg);
  height: 20px;
  width: 20px;
  margin-left: 5px;
  margin-bottom: 10px;
}
.chat .chat_container .chatWrapper .leftSidebar {
  width: 0px;
  height: calc(100vh - 94px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.chat .chat_container .chatWrapper .leftSidebar .lsbar_search {
  margin-bottom: 20px;
}
.chat .chat_container .chatWrapper .leftSidebar .lsbar_search .lsdbar_searchform {
  display: flex;
  align-items: center;
  background-color: var(--background-modals);
  border-radius: 5px;
  width: 100%;
}
.chat .chat_container .chatWrapper .leftSidebar .lsbar_search .lsdbar_searchform .lsdbar_form_group {
  flex: 1;
}
.chat .chat_container .chatWrapper .leftSidebar .lsbar_search .lsdbar_searchform .lsdbar_form_group .lsdbar_search_input {
  padding: 10px;
  border: none;
  width: 95%;
  background-color: transparent;
}
.chat .chat_container .chatWrapper .leftSidebar .lsbar_search .lsdbar_searchform .lsdbar_search_button {
  all: unset;
  cursor: pointer;
  padding: 7px 10px 5px 10px;
}
.chat .chat_container .chatWrapper .leftSidebar .lsbar_search .lsdbar_searchform .lsdbar_search_button .lsdbar_search_Ic {
  height: 15px;
  width: 15px;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_item_cat_active {
  flex: 1;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item {
  margin-bottom: 2px;
  border-bottom: 1px solid #bfbfbf;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsbar_it_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsbar_it_title:hover {
  background-color: var(--background-modals);
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsbar_it_title span {
  text-transform: uppercase;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsbar_it_title .lsdbar_it_Ic {
  height: 15px;
  width: 15px;
  margin-top: 2px;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsdbar_cat_cont {
  height: -moz-min-content;
  height: min-content;
  overflow-y: scroll;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsdbar_cat_cont::-webkit-scrollbar {
  display: none;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsdbar_cat_cont .wecare_it_user {
  padding: 10px 0 10px 10px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  border-radius: 5px;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsdbar_cat_cont .wecare_it_user:hover {
  background-color: #fff;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsdbar_cat_cont .wecare_it_user .wecare_lsdbar_profile {
  min-width: 30px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden !important;
  cursor: pointer;
  border: 2px solid #9935e8;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsdbar_cat_cont .wecare_it_user .wecare_lsdbar_profile .wecare_user_profile_sidebar {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  margin-top: 5px;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsdbar_cat_cont .wecare_it_user .lsdbar_user_profile_texts {
  margin-left: 10px;
  flex: 1;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsdbar_cat_cont .wecare_it_user .lsdbar_user_profile_texts .lsdbar_user_name {
  font-family: "Apercu";
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 18px;
  color: var(--text-color);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsdbar_cat_cont .wecare_it_user .lsdbar_user_profile_texts .lsdbar_last_mess {
  font-size: 0.85rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-wrap: break-word;
  color: var(--text-color);
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsdbar_cat_cont .wecare_it_user .lsdbar_user_profile_meta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding-right: 10px;
  padding-bottom: 20px;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsdbar_cat_cont .wecare_it_user .lsdbar_user_profile_meta .lsdbar_lst_time {
  font-size: 0.7rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsdbar_cat_cont .lsdbar_new_chat {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom right, #14a9ff, #0088f0) !important;
  border: none;
  border-radius: 23px;
  color: #fff !important;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  line-height: 1.5rem;
  margin: 20px 0;
  outline: none;
  padding: 10px 20px;
  transition: 0.4s;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsdbar_cat_cont .lsdbar_new_chat .pa_plus_Ic {
  height: 15px;
  width: 15px;
  fill: #fff !important;
}
.chat .chat_container .chatWrapper .leftSidebar .lsdbar_categories .lsdbar_cat_item .lsdbar_cat_cont .lsdbar_new_chat span {
  margin-left: 5px;
}
.chat .chat_container .chatWrapper .playarea {
  flex: 1;
  background-color: var(--background-modals);
  border-radius: 10px;
  height: calc(100vh - 94px);
}
.chat .chat_container .chatWrapper .playarea .playarea_placeholder {
  position: relative;
  height: 100%;
}
.chat .chat_container .chatWrapper .playarea .playarea_placeholder .playarea_place_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.chat .chat_container .chatWrapper .playarea .playarea_placeholder .playarea_place_bottom {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
}
.chat .chat_container .chatWrapper .playarea .playarea_active {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_top {
  height: -moz-min-content;
  height: min-content;
  border-bottom: 1px solid #bfbfbf;
  box-shadow: 0px 2px 8px rgba(99, 99, 99, 0.2);
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_top .active_chatee_left {
  display: flex;
  align-items: center;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_top .active_chatee_left .active_chatee_profile {
  min-width: 30px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden !important;
  cursor: pointer;
  border: 2px solid #9935e8;
  margin-right: 10px;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_top .active_chatee_left .active_chatee_profile img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_top .active_chatee_left .active_chatee_meta h2 {
  font-family: "Apercu";
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 18px;
  color: var(--text-color);
  cursor: pointer;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_top .active_chatee_left .active_chatee_meta .active_chatee_ls {
  font-size: 0.85rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-wrap: break-word;
  color: var(--text-color);
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_top .active_chatee_right .three_vertical_dots {
  height: 20px;
  width: 20px;
  cursor: pointer;
  padding: 7px;
  border-radius: 50%;
  overflow: hidden;
  margin: 3px;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_top .active_chatee_right .three_vertical_dots:hover {
  background-color: rgba(65, 65, 65, 0.5921568627);
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_top .active_chatee_pop_up {
  position: absolute;
  right: 10px;
  top: 50px;
  z-index: 999;
  background-color: #fff;
  height: -moz-min-content;
  height: min-content;
  width: 150px;
  box-shadow: 0px 2px 8px rgba(77, 77, 77, 0.568627451);
  border-radius: 5px;
  overflow: hidden;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_top .active_chatee_pop_up ul li {
  padding: 5px 5px 5px 10px;
  cursor: pointer;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_top .active_chatee_pop_up ul li:hover {
  background-color: rgba(137, 137, 137, 0.5921568627);
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle {
  height: calc(100vh - 94px - 35px - 50px);
  padding: 15px;
  padding-bottom: 5px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle::-webkit-scrollbar {
  display: none;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay_sec_info {
  background-color: var(--background-modals);
  color: var(--text-color);
  padding: 5px;
  border-radius: 5px;
  width: 50%;
  margin: 0 auto;
  align-self: center;
  text-align: center;
  font-size: 0.8rem;
  cursor: pointer;
  align-self: flex-start;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .incoming_message {
  display: flex;
  align-items: flex-start;
  width: 80%;
  margin: 20px 0;
  align-self: flex-start;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .incoming_message .in_mess_sender_profile {
  min-width: 30px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden !important;
  cursor: pointer;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .incoming_message .helpline_message {
  background-color: #92d0ff !important;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .incoming_message .in_mess_content {
  margin-left: 10px;
  background-color: var(--background-modals);
  padding: 5px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  min-width: 70px;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .incoming_message .in_mess_content .inc_mess_meta {
  display: flex;
  align-items: center;
  gap: 5px;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .incoming_message .in_mess_content .inc_mess_meta .inc_sender_name {
  font-family: "Apercu";
  font-size: 0.8rem;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .incoming_message .in_mess_content .inc_mess_meta .inc_mess_time {
  font-size: 0.8rem;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .incoming_message .in_mess_content .inc_mess_meta .inc_mess_misc {
  font-family: "Apercu";
  background-color: #ff9428;
  padding: 2px 3px;
  border-radius: 3px;
  font-size: 0.6rem;
  font-weight: 800;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .incoming_message .in_mess_content .incoming_cont_message {
  padding-top: 5px;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .incoming_message .in_mess_content .incoming_cont_message .inc_th_content {
  font-family: "Apercu";
  font-size: 1.2rem;
  font-weight: 500;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .outgoing_message {
  display: flex;
  align-items: flex-start;
  max-width: 80%;
  margin: 20px 0;
  align-self: flex-end;
  cursor: pointer;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .outgoing_message .out_mess_content {
  margin-right: 10px;
  background-color: var(--background-modals);
  padding: 5px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  min-width: 70px;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .outgoing_message .out_mess_content .out_mess_meta {
  display: flex;
  align-items: center;
  gap: 5px;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .outgoing_message .out_mess_content .out_mess_meta .out_mess_time {
  font-size: 0.8rem;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .outgoing_message .out_mess_content .outgoing_cont_message {
  padding-top: 5px;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .outgoing_message .out_mess_content .outgoing_cont_message .out_th_content {
  font-family: "Apercu";
  font-size: 1.2rem;
  font-weight: 500;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_middle .messplay .outgoing_message .out_mess_sender_profile {
  min-width: 30px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden !important;
  cursor: pointer;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_bottom {
  height: -moz-min-content;
  height: min-content;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_bottom .pa_chat_wrapper {
  display: flex;
  align-items: center;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_bottom .pa_chat_wrapper .pa_widgets {
  padding: 0 10px;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_bottom .pa_chat_wrapper .pa_widgets .pa_plus_Ic {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_bottom .pa_chat_wrapper .pa_chat_form {
  flex: 1;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_bottom .pa_chat_wrapper .pa_chat_form .pa_form_chat {
  display: flex;
  align-items: center;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_bottom .pa_chat_wrapper .pa_chat_form .pa_form_chat .pa_chat_input {
  flex: 1;
  background-color: var(--background-color-one);
  outline: none;
  border-radius: 5px;
  display: block;
  min-height: 30px;
  height: 30px;
  max-height: 98px;
  overflow-x: auto;
  line-height: 21px;
  padding: 10px;
  font-size: 15px;
  line-height: 25px;
  float: left;
  border: 1px solid gray;
  resize: none;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_bottom .pa_chat_wrapper .pa_chat_form .pa_form_chat .pa_chat_input::-webkit-scrollbar {
  display: none;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_bottom .pa_chat_wrapper .pa_chat_form .pa_form_chat .pa_chat_submit_btn {
  all: unset;
  padding: 10px 20px;
  cursor: pointer;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_bottom .pa_chat_wrapper .pa_chat_form .pa_form_chat .pa_chat_submit_btn .pa_send_Ic {
  height: 20px;
  width: 20px;
}
.chat .chat_container .chatWrapper .playarea .playarea_active .pa_bottom .pa_btm_info {
  text-align: center;
  margin: 5px 0;
  font-size: 0.8rem;
}
.chat .chat_container .chatWrapper .rightSidebar {
  width: 0%;
  background-color: var(--background-modals);
  border-radius: 10px;
  height: calc(100vh - 94px);
}

[contentEditable=true]:empty:not(:focus):before {
  content: attr(data-text);
}

.typing {
  display: block;
  width: 60px;
  height: 40px;
  border-radius: 20px;
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #8d8d8d;
  margin: 3px;
}
.circle.scaling {
  animation: typing 1000ms ease-in-out infinite;
  animation-delay: 3600ms;
}
.circle.bouncing {
  animation: bounce 1000ms ease-in-out infinite;
  animation-delay: 3600ms;
}
.circle:nth-child(1) {
  animation-delay: 0ms;
}
.circle:nth-child(2) {
  animation-delay: 333ms;
}
.circle:nth-child(3) {
  animation-delay: 666ms;
}

@keyframes typing {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.wecare_it_user {
  padding: 10px 0 10px 10px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  border-radius: 5px;
}
.wecare_it_user:hover {
  background-color: #fff;
}
.wecare_it_user .wecare_lsdbar_profile {
  min-width: 30px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden !important;
  cursor: pointer;
  border: 2px solid #9935e8;
}
.wecare_it_user .wecare_lsdbar_profile .wecare_user_profile_sidebar {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  margin-top: 5px;
}
.wecare_it_user .lsdbar_user_profile_texts {
  margin-left: 10px;
  flex: 1;
}
.wecare_it_user .lsdbar_user_profile_texts .lsdbar_user_name {
  font-family: "Apercu";
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 18px;
  color: var(--text-color);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.wecare_it_user .lsdbar_user_profile_texts .lsdbar_last_mess {
  font-size: 0.85rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-wrap: break-word;
  color: var(--text-color);
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.wecare_it_user .lsdbar_user_profile_meta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end !important;
  padding-right: 10px;
  padding-bottom: 0px !important;
}
.wecare_it_user .lsdbar_user_profile_meta .lsdbar_lst_time {
  font-size: 0.7rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.wecare_it_user .lsdbar_user_profile_meta .lsdbar_lst_delete_bot {
  height: 15px;
  width: 15px;
  cursor: pointer;
  fill: #ff7676;
  transition: 2s;
}
.wecare_it_user .lsdbar_user_profile_meta .lsdbar_lst_delete_bot:hover {
  fill: red;
  transform: scale(1.05);
}

.r_blg_body__content p {
  margin-bottom: 10px;
}
.r_blg_body__content p strong {
  font-size: 1.5rem;
}
.r_blg_body__content ul {
  margin: 10px 20px 10px 25px;
}
.r_blg_body__content ul li {
  margin-bottom: 10px;
  list-style: circle;
}/*# sourceMappingURL=chat.css.map */